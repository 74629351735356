.solve-question-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 120px);
  padding: 20px;
}

.solve-question-container {
  background-color: #fff;
  border: 1px solid #00C1D4;
  border-radius: 15px;
  padding: 40px;
  box-shadow: 0 2px 6px rgba(0, 193, 212, 0.1);
  background: linear-gradient(to bottom, rgba(0, 193, 212, 0.05), rgba(255, 255, 255, 1));
  width: 100%;
  max-width: 1000px;
}

.question-text-container {
  margin-bottom: 30px;
}

.question-title {
  font-size: 1.2rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 15px;
  display: block;
}

.question-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin: 15px 0;
}

.question-text {
  font-size: 1.1rem;
  color: #444;
  line-height: 1.6;
}

/* Image Upload and Preview Styles */
.uploaded-images {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  margin: 20px 0;
}

.image-preview-container {
  position: relative;
}

.image-preview {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
}

.btn-cancel {
  width: 100%;
  margin-top: 8px;
}

/* Button Grid Layout */
.button-grid {
  margin-top: 30px;
}

.button-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.top-row {
  margin-bottom: 20px;
}

.bottom-row {
  display: grid;
  grid-template-columns: auto auto auto; /* Changed to three equal columns */
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}

/* Add specific styles for solve button */
.btn-solve {
  background: linear-gradient(to right, #00C1D4, #001B6C);
  min-width: 150px; /* Reduced width */
  justify-self: center;
  white-space: nowrap;
  text-align: center;
}

.btn-question-list {
  justify-self: start; /* Align to start */
  min-width: 120px;
}

.btn-explain {
  justify-self: end; /* Align to end */
  min-width: 120px;
}

/* Rest of your styles remain the same */

@media (max-width: 768px) {
  .solve-question-container {
    padding: 20px;
  }

  .button-row {
    flex-direction: column;
    gap: 10px;
  }

  .bottom-row {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .btn {
    width: 100%;
  }

  .btn-solve,
  .btn-question-list,
  .btn-explain {
    justify-self: stretch;
  }
}