
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.login-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  background: linear-gradient(to right, #00C1D4, #001B6C);
  position: relative;
  overflow: hidden;
}

.login-wrapper::before {
  content: '';
  position: absolute;
  width: 2000px;
  height: 2000px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  top: -1000px;
  right: -1000px;
  animation: move 15s infinite linear;
}

@keyframes move {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.login-form-container {
  width: 100%;
  max-width: 400px;
  padding: 40px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0 8px 32px rgba(0, 193, 212, 0.2);
  backdrop-filter: blur(4px);
  border: 1px solid rgba(0, 193, 212, 0.18);
  position: relative;
  transform: translateY(0);
  transition: transform 0.3s ease;
}

.login-form-container:hover {
  transform: translateY(-5px);
}

.login-title {
  text-align: center;
  margin-bottom: 30px;
  color: #001B6C;
  font-size: 28px;
  font-weight: 600;
}

.form-group {
  margin-bottom: 25px;
}

.form-control {
  width: 100%;
  padding: 12px 15px;
  border: 2px solid #e1e1e1;
  border-radius: 10px;
  font-size: 16px;
  transition: all 0.3s ease;
  outline: none;
}

.form-control:focus {
  border-color: #00C1D4;
  box-shadow: 0 0 0 3px rgba(0, 193, 212, 0.2);
}

.password-wrapper {
  position: relative;
}

.password-toggle-icon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #00C1D4;
  background: none;
  border: none;
  padding: 5px;
}

.password-toggle-icon:hover {
  color: #001B6C;
}

.login-button {
  width: 100%;
  padding: 12px;
  background: linear-gradient(to right, #00C1D4, #001B6C);
  border: none;
  border-radius: 10px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.login-button:hover {
  background: white;
  color: #001B6C;
  border: 1px solid #00C1D4;
}

.login-button:active {
  transform: translateY(0);
}

.forgot-password {
  text-align: center;
  margin-top: 20px;
}

.forgot-password a {
  color: #00C1D4;
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s ease;
}

.forgot-password a:hover {
  color: #001B6C;
  text-decoration: underline;
}

/* Responsive styles */
@media (max-width: 480px) {
  .login-form-container {
    padding: 30px 20px;
  }
}