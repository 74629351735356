.signup-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
}

.signup-form-container {
  width: 100%;
  max-width: 400px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  background-color: #fff;
}

.signup-title {
  text-align: center;
  margin-bottom: 20px;
}

.signup-form {
  margin-bottom: 15px;
}

.signup-button {
  width: 100%;
}