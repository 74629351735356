.result-page-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  min-height: calc(100vh - 120px);
}

.result-content {
  background-color: #fff;
  padding: 30px;
  border: 2px solid #00C1D4;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 193, 212, 0.1);
  margin-top: 20px;
  width: 100%;
  overflow-y: auto;
}

.result-title {
  color: #001B6C;
  text-align: center;
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: 600;
}

.result-question {
  background: linear-gradient(to bottom, rgba(0, 193, 212, 0.1), rgba(255, 255, 255, 1));
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #00C1D4;
  border-radius: 8px;
}

.result-explanation {
  background: rgba(255, 255, 255, 0.9);
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #00C1D4;
  border-radius: 8px;
  text-align: left;
}

.result-explanation ol {
  margin-left: 0;
  padding-left: 20px;
  text-align: left;
}

.result-explanation li {
  margin-bottom: 10px;
  line-height: 1.6;
  text-align: left;
}

.result-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 40px;
  padding: 20px 0;
}

.next-question-btn {
  background: linear-gradient(to right, #00C1D4, #001B6C);
  border: none;
  color: white;
  padding: 12px 24px;
  border-radius: 5px;
  font-weight: 500;
  min-width: 160px;
  transition: all 0.3s ease;
}

.next-question-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 193, 212, 0.2);
}

.dashboard-btn {
  background: white;
  border: 2px solid #00C1D4;
  color: #001B6C;
  padding: 12px 24px;
  border-radius: 5px;
  font-weight: 500;
  min-width: 160px;
  transition: all 0.3s ease;
}

.dashboard-btn:hover {
  background: rgba(0, 193, 212, 0.1);
  transform: translateY(-2px);
}

.practice-btn {
  background: linear-gradient(to right, #00C1D4, #001B6C);
  border: none;
  color: white;
  padding: 12px 24px;
  border-radius: 5px;
  font-weight: 500;
  min-width: 200px;
  transition: all 0.3s ease;
}

.practice-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 193, 212, 0.2);
}

.result-accordion .accordion-item {
  margin-bottom: 15px;
  border: 1px solid #00C1D4;
  border-radius: 8px;
  overflow: hidden;
}

.result-accordion .accordion-header button {
  background: linear-gradient(to right, #00C1D4, #001B6C);
  color: white;
  padding: 15px 20px;
  width: 100%;
  text-align: left;
}

.result-accordion .accordion-body {
  background: linear-gradient(to bottom, rgba(0, 193, 212, 0.1), rgba(255, 255, 255, 1));
  padding: 20px;
}

.question-image-container {
  margin: 20px 0;
  text-align: center;
}

.question-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.result-solution {
  text-align: left;
  padding: 20px;
  margin-top: 20px;
}

.result-solution ol {
  margin-left: 0;
  padding-left: 20px;
}

.result-solution li {
  text-align: left;
  margin-bottom: 10px;
}

.solution-header {
  text-align: left;
  font-weight: bold;
  margin-bottom: 15px;
}

/* New Example Section Styles */
.example-section {
  background: rgba(0, 193, 212, 0.05);
  padding: 20px;
  border-radius: 8px;
  margin: 15px 0;
}

.example-content {
  margin-top: 10px;
}

.example-steps {
  margin-top: 15px;
}

.example-step {
  margin-bottom: 12px;
  line-height: 1.6;
  padding-left: 20px;
  text-align: left;
}

.example-step strong {
  margin-right: 8px;
  color: #001B6C;
}

.concept-title {
  color: #001B6C;
  font-size: 1.1em;
  margin-bottom: 15px;
}

.chapter-name {
  margin-bottom: 15px;
}

.example-header {
  margin-bottom: 10px;
  color: #001B6C;
}

.explanation {
  margin-top: 15px;
}

/* Solution Steps Styling */
.solution-steps {
  padding-left: 20px;
  text-align: left;
}

.solution-step {
  margin-bottom: 12px;
  line-height: 1.6;
  text-align: left;
}

@media (max-width: 768px) {
  .result-buttons {
    flex-direction: column;
    align-items: stretch;
  }

  .next-question-btn,
  .dashboard-btn,
  .practice-btn {
    width: 100%;
    margin: 5px 0;
  }

  .result-content {
    padding: 20px;
  }
}

@media (max-width: 576px) {
  .result-page-container {
    padding: 10px;
  }

  .result-title {
    font-size: 20px;
  }
}