/* General Styles for Body and HTML */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* Navbar Styles */
.custom-navbar {
  background: linear-gradient(to right, #00C1D4, #001B6C) !important;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  height: 56px;
}

/* Navbar Brand */
.custom-navbar .navbar-brand {
  color: white !important;
}

/* Navbar Links */
.custom-navbar .nav-link {
  color: white !important;
  padding: 0.5rem 1rem;
  transition: color 0.3s ease;
  font-weight: bold;
}

/* Active Navbar Link */
.custom-navbar .nav-link.active {
  color: #e0f7fa !important;
}

/* Navbar Link Hover */
.custom-navbar .nav-link:hover {
  color: rgba(255, 255, 255, 0.8) !important;
}

/* Navbar Logout Icon */
.custom-navbar .logout-icon {
  color: white !important;
}

/* Mobile Sidebar Styles */
@media (max-width: 761px) {
  .custom-navbar .offcanvas-body {
    background: linear-gradient(to right, #00C1D4, #001B6C) !important;
  }
  
  .custom-navbar .offcanvas-body .nav-link {
    color: white !important;
  }
  
  .custom-navbar .offcanvas-body .logout-icon {
    color: white !important;
  }

  .username-text {
    color: white !important;
  }
}

/* Main content area */
.flex-fill {
  flex: 1;
  width: 100%;
  margin-top: -55px;
  overflow-y: auto;
  box-sizing: border-box;
  padding-bottom: 20px;
  height: 100%;
}

/* Footer Styles */
.footer {
  background: linear-gradient(to right, #00C1D4, #001B6C);
  color: white;
  padding: 10px 20px;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  height: 60px;
}

/* Username text */
.username-text {
  color: white;
  font-weight: bold;
}

/* Button styles */
.custom-button {
  color: white;
  border-color: white;
  transition: all 0.3s ease;
}

.custom-button:hover {
  background-color: white;
  color: #00C1D4;
}

.custom-button.active {
  background: linear-gradient(to right, #00C1D4, #001B6C);
  color: white;
  border-color: white;
}