.question-modal .modal-content {
  border-radius: 15px;
  border: none;
}

.question-list-container {
  max-height: 70vh;
  overflow-y: auto;
  padding: 15px;
}

.question-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.question-item {
  display: flex;
  align-items: flex-start;
  padding: 15px;
  margin-bottom: 10px;
  border: 1px solid rgba(0, 193, 212, 0.3);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.question-item:hover {
  background: rgba(0, 193, 212, 0.05);
  transform: translateY(-2px);
}

.question-number {
  background: linear-gradient(to right, #00C1D4, #001B6C);
  color: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  flex-shrink: 0;
}

.question-content {
  flex: 1;
}

.question-text {
  margin-bottom: 10px;
}

.question-image-preview {
  max-width: 200px;
  margin-top: 10px;
}

.preview-image {
  width: 100%;
  height: auto;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.close-button {
  background: linear-gradient(to right, #00C1D4, #001B6C);
  border: none;
}

.close-button:hover {
  background: #001B6C;
}