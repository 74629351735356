.similar-questions-container {
  padding: 1rem 2rem;
  max-width: 1400px; /* Increased max-width */
  margin: 60px auto 0 auto; /* Adjusted top margin */
  height: calc(100vh - 120px); /* Adjusted height calculation */
  overflow-y: auto;
}

.similar-questions-title {
  color: #001B6C;
  text-align: center;
  margin: 0 0 1.5rem 0; /* Adjusted margin */
  font-weight: 600;
  font-size: 28px;
}

.questions-section {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.5rem;
  height: auto; /* Remove fixed height */
}

.original-question-card {
  margin-bottom: 1.5rem;
  border: 1px solid #00C1D4;
}

.original-question-card .card-header {
  background: linear-gradient(to right, #00C1D4, #001B6C);
  color: white;
  font-weight: 500;
  padding: 0.75rem 1.5rem;
  font-size: 1.2rem;
}

.original-question-card .card-body {
  padding: 1.5rem;
  font-size: 1.1rem;
  line-height: 1.6;
  background-color: #fff;
}

.theory-concepts-section {
  margin: 1.5rem 0;
  padding: 1.5rem;
  background: rgba(0, 193, 212, 0.05);
  border-radius: 8px;
  border: 1px solid rgba(0, 193, 212, 0.2);
}

.theory-concepts-section h4 {
  color: #001B6C;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  font-weight: 600;
}

.theory-content {
  font-size: 1.1rem;
  line-height: 1.7;
  color: #333;
  padding: 0 1rem;
}

.theory-content p {
  margin-bottom: 1rem;
}

.concept-item {
  margin-bottom: 1rem;
  padding-left: 1rem;
}

.concept-title {
  font-weight: 600;
  color: #001B6C;
  margin-bottom: 0.5rem;
}

.concept-point {
  margin-left: 1.5rem;
  margin-bottom: 0.5rem;
  position: relative;
}

.concept-point::before {
  content: "•";
  position: absolute;
  left: -1rem;
  color: #00C1D4;
}

/* Main content area scrollbar */
.similar-questions-container::-webkit-scrollbar {
  width: 10px;
}

.similar-questions-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

.similar-questions-container::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom, #00C1D4, #001B6C);
  border-radius: 5px;
}

.similar-questions-container::-webkit-scrollbar-thumb:hover {
  background: #001B6C;
}

/* Navigation buttons */
.navigation-buttons {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  position: sticky;
  bottom: 0;
  background: white;
  border-top: 1px solid rgba(0, 193, 212, 0.2);
  margin-top: 1rem;
}

.navigation-button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border-radius: 6px;
  transition: all 0.3s ease;
}

.back-button {
  background: white;
  color: #001B6C;
  border: 2px solid #00C1D4;
}

.back-button:hover {
  background: rgba(0, 193, 212, 0.1);
  transform: translateY(-2px);
}

/* Responsive Design */
@media (max-width: 1200px) {
  .similar-questions-container {
    padding: 1rem;
    margin-top: 50px;
  }
}

@media (max-width: 768px) {
  .similar-questions-container {
    padding: 0.75rem;
    height: calc(100vh - 100px);
  }

  .questions-section {
    padding: 1rem;
  }

  .theory-concepts-section {
    padding: 1rem;
  }

  .theory-content {
    font-size: 1rem;
    padding: 0 0.5rem;
  }
}

/* Footer adjustment */
.content-wrapper {
  min-height: calc(100vh - 60px); /* Account for footer height */
  padding-bottom: 60px; /* Space for footer */
  position: relative;
}