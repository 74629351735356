/* Top Navigation/Header Bar */
.Dashboard {
  background: linear-gradient(to right, #00C1D4, #001B6C);
  color: white;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.Dashboard a {
  color: white;
  text-decoration: none;
  margin-left: 15px;
}

.Dashboard a:hover {
  color: rgba(255, 255, 255, 0.8);
}

/* Header and Footer Styles */
.header, .footer {
  background: linear-gradient(to right, #00C1D4, #001B6C);
  color: white;
  padding: 15px;
  text-align: center;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

/* Form Styles */
.form-container {
  background-color: #fff;
  background: linear-gradient(to bottom, rgba(0, 193, 212, 0.1), rgba(0, 27, 108, 0.05));
  border: 1px solid #00C1D4;
  border-radius: 30px;
  padding: 50px;
  box-shadow: 0 4px 8px rgba(0, 27, 108, 0.1);
  max-width: 1000px;
  width: 100%;
  margin: 0px auto;
}

/* Input and Select Styles */
.form-control {
  border: 1px solid #00C1D4;
  border-radius: 4px;
}

.form-control:focus {
  border-color: #001B6C;
  box-shadow: 0 0 0 0.2rem rgba(0, 193, 212, 0.25);
}

/* Button Styles */
.btn-generate {
  background: linear-gradient(to right, #00C1D4, #001B6C) !important;
  border: none !important;
  color: white !important;
  transition: all 0.3s ease;
}

.btn-generate:hover {
  background: white !important;
  border: 1px solid #00C1D4 !important;
  color: #001B6C !important;
}

/* Modal Styles */
.modal-90w {
  max-width: 90%;
  margin: auto;
}

.question-list-container {
  max-height: 400px;
  overflow-y: auto;
  padding: 10px;
}

.question-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.question-item {
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #00C1D4;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.2s ease;
  background: linear-gradient(to bottom, rgba(0, 193, 212, 0.1), rgba(0, 27, 108, 0.05));
}

.question-item:hover {
  background: linear-gradient(to bottom, rgba(0, 193, 212, 0.2), rgba(0, 27, 108, 0.1));
}

.question-number {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  background: linear-gradient(to right, #00C1D4, #001B6C);
  color: #fff;
  text-align: center;
  margin-right: 10px;
  border: none;
}

.question-text {
  flex: 1;
  padding: 5px;
  color: #333;
  border: none !important;
}

.question-modal .modal-title {
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  width: 100%;
  color: #001B6C;
}

/* Label Styles */
label {
  color: #001B6C;
  font-weight: 500;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .header, .footer {
      font-size: 14px;
      padding: 10px;
  }

  .Dashboard {
      padding: 10px;
      flex-wrap: wrap;
  }

  .form-container {
      padding: 15px;
      max-width: 100%;
      margin: 10px;
      border-radius: 15px;
  }

  .btn-generate {
      width: 100%;
      margin-top: 10px;
  }
}

/* Additional Styles for Select Dropdowns */
select.form-control {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, #00C1D4 50%),
                    linear-gradient(135deg, #00C1D4 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
                       calc(100% - 15px) calc(1em + 2px);
  background-size: 5px 5px,
                  5px 5px;
  background-repeat: no-repeat;
  padding-right: 30px;
}

select.form-control:focus {
  background-image: linear-gradient(45deg, transparent 50%, #001B6C 50%),
                    linear-gradient(135deg, #001B6C 50%, transparent 50%);
}

/* Custom scrollbar for webkit browsers */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 193, 212, 0.1);
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom, #00C1D4, #001B6C);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(to bottom, #00A1B4, #001548);
}